import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import {
    Layout,
    PostSection,
    MagazineSection,
    Events,
    Subscribe,
    SearchSection,
    Header,
} from "../components/common";
import blogimg from '../images/blogimg.jpeg'
// import { MetaData } from "../components/common/meta";

const XMLHttpRequest = require("xhr2");
const xhr = new XMLHttpRequest();

/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * The number of posts that should appear per page can be setup
 * in /utils/siteConfig.js under `postsPerPage`.
 *
 */
const Index = ({ data, location, pageContext }) => {
    const posts = data.allGhostPost.edges;
    // console.log(posts)
    return (
        <>
            {/* <MetaData
                data={data}
                location={location}
                type="article"
            /> */}
            <Helmet>
                <meta name="title" content="Sharing knowledge is our DNA 🧬 - xHub Blog" />
                <meta
                    name="description"
                    content="A blog where the xTalents share their knowledge and expertise about different topics and technologies. Subscribe to our newsletter and never miss an article!"
                />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://blog.x-hub.io/" />
                <meta property="og:title" content="Sharing knowledge is our DNA 🧬 - xHub Blog" />
                <meta
                    property="og:description"
                    content="A blog where the xTalents share their knowledge and expertise about different topics and technologies. Subscribe to our newsletter and never miss an article!"
                />
                <meta property="og:image" content={blogimg} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://blog.x-hub.io/" />
                <meta property="twitter:title" content="Sharing knowledge is our DNA 🧬 - xHub Blog" />
                <meta
                    property="twitter:description"
                    content="A blog where the xTalents share their knowledge and expertise about different topics and technologies. Subscribe to our newsletter and never miss an article!"
                />
                <meta property="twitter:image" content={blogimg} />
                <title>Sharing knowledge is our DNA 🧬 - xHub Blog </title>
            </Helmet>
            <Layout isHome={true}>
                <Header>
                    <SearchSection />
                </Header>
                <PostSection />
                <MagazineSection />
                <Events />
                <Subscribe />
            </Layout>
        </>
    );
};

export default Index;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
    query GhostPostQuery($limit: Int!, $skip: Int!) {
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
